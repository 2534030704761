import './style.css'

export default function LogoIntersection2() {
    return (
        <div className='logoIntersection'>
            <div className='logoIntersection-area'>
                <div className='logoIntersection-text'>
                    <h3>usos incorretos</h3>
                    <p>A fim de manter a identidade da marca, o logotipo nunca deverá ser
                        alterado, seja nas suas cores, diagramação ou proporções. Verifique
                        os usos incorretos da marca e compare com a versão correta</p>
                </div>
            </div>
        </div>
    )
}