import './style.css'
import { useState } from 'react'
import Apresentacao from './mock_apresentacao.png'
import Assinatura from './mock-assinatura.png'
import CartaoDeVisita from './mock-cartao_visita.png'
import PapelTimbrado from './mock-papel_timbrado.png'
import VersoCracha from './mock-verso-cracha.png'


export default function PapelariaSecondSection() {

    const [img1, setImg1] = useState(Assinatura)
    const [img2, setImg2] = useState(Apresentacao)
    const [img3, setImg3] = useState(VersoCracha)
    const [img4, setImg4] = useState(CartaoDeVisita)
    const [img5, setImg5] = useState(PapelTimbrado)

    function changeImgPrev() {
        if (img1 === Assinatura && img5 === PapelTimbrado) {
            setImg1(PapelTimbrado)
            setImg2(Assinatura)
            setImg3(Apresentacao)
            setImg4(VersoCracha)
            setImg5(CartaoDeVisita)
        }
        if (img1 === Apresentacao && img5 === Assinatura) {
            setImg1(Assinatura)
            setImg2(Apresentacao)
            setImg3(VersoCracha)
            setImg4(CartaoDeVisita)
            setImg5(PapelTimbrado)
        }
        if (img1 === VersoCracha && img5 === Apresentacao) {
            setImg1(Apresentacao)
            setImg2(VersoCracha)
            setImg3(CartaoDeVisita)
            setImg4(PapelTimbrado)
            setImg5(Assinatura)
        }
        if (img1 === CartaoDeVisita && img5 === VersoCracha) {
            setImg1(VersoCracha)
            setImg2(CartaoDeVisita)
            setImg3(PapelTimbrado)
            setImg4(Assinatura)
            setImg5(Apresentacao)
        }
        if (img1 === PapelTimbrado && img5 === CartaoDeVisita) {
            setImg1(CartaoDeVisita)
            setImg2(PapelTimbrado)
            setImg3(Assinatura)
            setImg4(Apresentacao)
            setImg5(VersoCracha)
        }
    }

    function changeImgNext() {
        if (img1 === Assinatura && img5 === PapelTimbrado) {
            console.log("funcionaporra")
            setImg1(Apresentacao)
            setImg2(VersoCracha)
            setImg3(CartaoDeVisita)
            setImg4(PapelTimbrado)
            setImg5(Assinatura)
        }
        if (img1 === Apresentacao && img5 === Assinatura) {
            setImg1(VersoCracha)
            setImg2(CartaoDeVisita)
            setImg3(PapelTimbrado)
            setImg4(Assinatura)
            setImg5(Apresentacao)
        }
        if (img1 === VersoCracha && img5 === Apresentacao) {
            setImg1(CartaoDeVisita)
            setImg2(PapelTimbrado)
            setImg3(Assinatura)
            setImg4(Apresentacao)
            setImg5(VersoCracha)
        }
        if (img1 === CartaoDeVisita && img5 === VersoCracha) {
            setImg1(PapelTimbrado)
            setImg2(Assinatura)
            setImg3(Apresentacao)
            setImg4(VersoCracha)
            setImg5(CartaoDeVisita)
        }
        if (img1 === PapelTimbrado && img5 === CartaoDeVisita) {
            setImg1(Assinatura)
            setImg2(Apresentacao)
            setImg3(VersoCracha)
            setImg4(CartaoDeVisita)
            setImg5(PapelTimbrado)
        }
    }

    return (
        <div className='papelariaSecondSection'>
            <div className='papelariaSecondSection-area'>
                <h2>Nossos materiais</h2>
                <div className='papelariaSecondSection-box'>
                    <i onClick={changeImgNext} className="fa-solid fa-chevron-right"></i>
                    <div className='papelaria-img1' style={{ backgroundImage: "url(" + img1 + ")" }}></div>
                    <i onClick={changeImgPrev} className="fa-solid fa-chevron-left"></i>
                    <div className='papelaria-carousel'>

                        <div className='papelaria-img2' style={{ backgroundImage: "url(" + img2 + ")" }}></div>
                        <div className='papelaria-img3' style={{ backgroundImage: "url(" + img3 + ")" }}></div>
                        <div className='papelaria-img4' style={{ backgroundImage: "url(" + img4 + ")" }}></div>
                        <div className='papelaria-img5' style={{ backgroundImage: "url(" + img5 + ")" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}