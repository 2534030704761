import '../App.css'
import HeaderAlt from '../components/Logo/HeaderAlt'
import TipografiaIntersection from '../components/Tipografia/TipografiaIntersection'
import TipografiaMainSection from '../components/Tipografia/TipografiaMainSection'
import TipografiaSecondSection from '../components/Tipografia/TipografiaSecondSection'

export default function Tipografia() {
    return (
        <div className='tipografia'>
            <HeaderAlt />
            <TipografiaMainSection />
            <TipografiaIntersection />
            <TipografiaSecondSection />
        </div>
    )
}