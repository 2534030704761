import '../App.css'
import HeaderAlt from '../components/Logo/HeaderAlt'
import PapelariaMainSection from '../components/Papelaria/PapelariaMainSection'
import PapelariaSecondSection from '../components/Papelaria/PapelariaSecondSection'

export default function Papelaria() {
    return (
        <div className='papelaria'>
            <HeaderAlt />
            <PapelariaMainSection />
            <PapelariaSecondSection />
        </div>
    )
}