import './style.css'

export default function CoresThirdSection() {
    return (
        <div className='coresThirdSection'>
            <div className='coresThirdSection-area'>
                <h1>Positivo, <br/>negativo e <br/>escala de cinza</h1>
                <div className='coresThirdSection-box'>
                    <div className='box'>
                        <img src='CORES_VARIACAO_BRANCO.png' alt=''/>
                        <img src='CORES_VARIACAO_PRETO.png' alt=''/>
                    </div>
                    <img src='./CORES_VARICAO-ICONE.png' alt=''/>
                </div>
            </div>
        </div>
    )
}