import './style.css'
import { Link } from 'react-router-dom'

export default function ThirdSection() {

    return (
        <div className='thirdSection'>
            <div className='thirdSection-area'>
                <div className='thirdSection-grid'>
                    <Link to='/logo' id='logoSection' onClick={() => window.scrollTo(0, 0)}><p>Logo</p></Link>
                    <Link to='/cores' id='coresSection' onClick={() => window.scrollTo(0, 0)}><p>Cores</p></Link>
                    <Link to='/tipografia' id='tipografia' onClick={() => window.scrollTo(0, 0)}><p>Tipografia</p></Link>
                    <a href='#'><p>Grafismo<img src='./logo192.png'/></p></a>
                    <a href='#'><p>Ícones<img src='./logo192.png'/></p></a>
                    <a href='#'><p>Fotografia<img src='./logo192.png'/></p></a>
                </div>
            </div>
        </div>
    )
}