import '../App.css'
import CoresMainSection from '../components/Cores/MainSection'
import CoresSecondSection from '../components/Cores/SecondSection'
import CoresThirdSection from '../components/Cores/ThirdSection'
import HeaderAlt from '../components/Logo/HeaderAlt'

export default function Cores() {
    return (
        <div className='cores'>
            <HeaderAlt />
            <CoresMainSection />
            <CoresSecondSection />
            <CoresThirdSection />
        </div>
    )
}