import './style.css'

export default function LogoSixSection() {
    return (
        <div className='logoSixSection'>
            <div className='logoSixSection-area'>
                <div className='logoSixSection-exemples'>
                    <img src='./rotacionar-logo.png' alt='' />
                    <p>Não rotacionar a marca.</p>
                </div>
                <div className='logoSixSection-exemples'>
                    <img src='./esquema-de-cores.png' alt='' />
                    <p>Não alterar o esquema de cores.</p>
                </div>
                <div className='logoSixSection-exemples'>
                    <img src='./nao-alongar.png' alt='' />
                    <p>Não alongue ou altere a marca.</p>
                </div>
                <div className='logoSixSection-exemples'>
                    <img src='./nao-moficiar-a-tipografica.png' alt='' />
                    <p>Não modifique a tipografia.</p>
                </div>
                <div className='logoSixSection-exemples'>
                    <img src='./nao-aplicar-contornos-ou-sombras.png' alt='' />
                    <p>Não aplique contornos
                        ou sombras.</p>
                </div>
                <div className='logoSixSection-exemples'>
                    <img src='./nao-modificar-a-proporcao.png' alt='' />
                    <p>Não modifique a proporção
                        da marca.</p>
                </div>
                <div className='logoSixSection-exemples'>
                    <img src='./nao-utlizar-em-outline.png' alt='' />
                    <p>Não utilize a marca em outline.</p>
                </div>
            </div>
        </div>
    )
}