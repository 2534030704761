import './style.css'

export default function TipografiaIntersection() {
    return (
        <div className='tipografiaIntersection'>
            <div className='tipografiaIntersection-area'>
                <div className='tipografiaIntersection-text'>
                    <h3>Tipografia institucional</h3>
                    <p>A tipografia institucional cria o DNA visual da linguagem verbal, personalidade e tom da comunicação AlloyMit. Deve ser usado consistentemente em materiais institucionais, de comunicação, mídia editorial e também em elementos publicitários relacionados à AlloyMit.</p>
                </div>
            </div>
        </div>
    )
}