import { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'

export default function HeaderAlt() {

    const [abreMenu, setAbreMenu] = useState('menuDropDown disable')

    function openMenu() {
        if(abreMenu === 'menuDropDown disable') {
            setAbreMenu('menuDropDown')
        } else {
            setAbreMenu('menuDropDown disable')
        }
    }

    function closeMenu() {
        if(abreMenu === 'menuDropDown') {
            setAbreMenu('menuDropDown disable')
        } else {
            setAbreMenu('menuDropDown')
        }
    }

    return (
        <div className='altheader'>
            <div className='altheader-area'>
                <div></div>
                <div className='header-menu'>
                    <div className='altheader-menu-bar' onClick={openMenu}>
                        <p>menu</p>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                    <div className={abreMenu}>
                        <div className='menuDropDown-header'>
                            <Link to='/'><img src='./ALLOYMIT_LOGO_VARIACAO.png' alt='Logo da AlloyMit com variação' /></Link>
                            <div className='menuDropDown-header-close' onClick={closeMenu}>
                                <p>fechar</p>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                        <div className='menuDropDown-body'>
                            <div className='menuDropDown-body-header'>
                                <Link to='/nossa-essencia' onClick={() => window.scrollTo(0, 0)}>nossa essência</Link>
                                <a>tom de voz</a>
                            </div>
                            <div className='menuDropDown-body-main'>
                            <Link to='/logo' onClick={() => window.scrollTo(0, 0)}>logo</Link>
                                <Link to='/cores' onClick={() => window.scrollTo(0, 0)}>cores</Link>
                                <Link to='/tipografia' onClick={() => window.scrollTo(0, 0)}>tipografia</Link>
                                <a>ícones</a>
                                <a>fotografia</a>
                                <a>ilustração</a>
                                <a>composição</a>
                                <a>inspiração</a>
                            </div>
                            <div className='menuDropDown-body-footer'>
                                <a>downloads</a>
                                <a>template ppt</a>
                                <Link to='/papelaria' onClick={() => window.scrollTo(0, 0)}>papelaria</Link>
                                <a>template para vídeos</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}