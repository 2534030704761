import './style.css'

export default function LogoMainSection() {
    return (
        <div className='logoMainSection'>
            <div className='logoMainSection-area'>
                <div className='logoMainSection-logoAlt1'>
                    <img src='./ALLOYMIT_ICONE_VARIACAO.png' alt='Icone da AlloyMit' />
                </div>
                <div className='logoMainSection-logo'>
                    <div className='logoMainSection-logoAlt2'>
                        <img src='./ALLOYMIT_LOGO.png' alt='Logo da AlloyMit' />
                    </div>
                    <div className='logoMainSection-logoAlt3'>
                        <img src='./ALLOYMIT_LOGO_VARIACAO.png' alt='Logo da AlloyMit' />
                    </div>
                </div>
                <i className="fa-solid fa-caret-down"></i>
            </div>
        </div>
    )
}