import './style.css'


export default function FirstSection() {
    return (
        <div className='firstSection'>
            <div className='firstSection-area'>
                <h1>Olá, somos<br />a <span>AlloyMiT</span></h1>
            </div>

            <div className='verMais'>
                <a href='#secondSection'>
                    <i className="fa-solid fa-chevron-down"></i>
                    <p>ver mais</p>
                </a>
            </div>

        </div>
    )
}