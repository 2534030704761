import './style.css'

export default function CoresMainSection() {
    return (
        <div className='coresMainSection'>
            <div className='coresMainSection-area'>
                <h1>nossas<br/>cores</h1>
                <i className="fa-solid fa-caret-down"></i>
            </div>
        </div>
    )
}