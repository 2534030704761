import './style.css'

export default function logoIntersection() {
    return (
        <div className='logoIntersection'>
            <div className='logoIntersection-area'>
                <div className='logoIntersection-text'>
                    <h3>área de segurança</h3>
                    <p>Para evitar interferência de outros elementos gráficos e preservar a integridade e
                        legibilidade da marca deve ser resguardado um espaço ao seu redor. A esse espaço
                        atribuímos o nome de “área de proteção”. Para determinar a área de proteção da
                        marca usaremos como medida a letra “O” da tagline, assim como mostrado.</p>
                </div>
            </div>
        </div>
    )
}