import { useEffect, useState } from 'react'
import './style.css'

export default function SecondSection() {

    const [text1, setText1] = useState('hide')
    const [text2, setText2] = useState('hide')
    const [text3, setText3] = useState('hide')
    const [text4, setText4] = useState('hide')
    const [text5, setText5] = useState('secondSection-box-area hide')

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) { 
                setText1('show') 
            } else {
                setText1('hide')
            }
            if (window.scrollY > 300) { 
                setText2('show') 
            } else {
                setText2('hide')
            }
            if (window.scrollY > 400) { 
                setText3('show') 
            } else {
                setText3('hide')
            }
            if (window.scrollY > 460) { 
                setText4('show') 
            } else {
                setText4('hide')
            }
            if (window.scrollY >= 520) { 
                setText5('secondSection-box-area showUp') 
            } else {
                setText5('secondSection-box-area hide')
            }
        })


    }, [])

    return (
        <div className='secondSection'>
            <div className='secondSection-area' id='secondSection'>
                <div className='secondSection-texto'>
                    <h1 className={text1}>somos um grupo de essência genuína <span>e propósito responsável</span></h1>
                    <p className={text2}><span>Conexão é a palavra que representa a essência do Grupo AlloyMit.</span> Queremos nos conectar com o que há de melhor nas pessoas. Por isso, acreditamos no trabalho multidisciplinar entre as equipes como forma de germinar ideias inovadoras e soluções criativas para aprimorar experiências. </p>
                    <p className={text3}><span>O Grupo AlloyMit integra pautas e politicas ambientais, sociais e de governança corporativa</span>  de forma estruturada, consistente e consciente.</p>
                </div>
                <div className='secondSection-box'>
                    <h1 className={text4}>Nosso diferencial</h1>
                    <div className={text5}>
                        <div className='box'>
                            <h3>Somos movidos por</h3>
                            <p>tecnologia, inovação e inspiração, dessa forma, estamos em constante evolução a fim de cumprir com o nosso propósito de transformar vidas e negócios.</p>
                        </div>
                        <div className='box'>
                            <h3>Gerir com responsabilidade</h3>
                            <p>Nossa busca é incansável para encontrar oportunidades de melhorar e gerar eficiência nos segmentos que atuamos. </p>
                        </div>
                        <div className='box'>
                            <h3>Como atuamos</h3>
                            <p>Trabalhamos com metas audaciosas de crescimento e desempenho, sempre com elevados padrões de controles operacionais e de risco.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}