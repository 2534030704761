import './style.css'

export default function PapelariaMainSection() {
    return (
        <div className='papelariaMainSection'>
            <div className='papelariaMainSection-area'>
                <h1>papelaria</h1>
                <i id='cartaoTitulo' className="fa-solid fa-cards-blank"></i>
                <i className="fa-solid fa-caret-down"></i>
            </div>
        </div>
    )
}