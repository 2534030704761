import { useEffect, useState } from 'react'
import './style.css'

export default function CoresSecondSection() {

    const [cor1, setCor1] = useState('coresSecondSection-cores cor1 hide')
    const [cor2, setCor2] = useState('coresSecondSection-cores cor2 hide')
    const [cor3, setCor3] = useState('coresSecondSection-cores cor3 hide')
    const [cor4, setCor4] = useState('coresSecondSection-cores cor4 hide')
    const [cor5, setCor5] = useState('coresSecondSection-cores cor5 hide')

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY >= 180) { 
                setCor1('coresSecondSection-cores cor1 showUp') 
            }
            if (window.scrollY >= 220) { 
                setCor2('coresSecondSection-cores cor2 showUp') 
            }
            if (window.scrollY >= 240) { 
                setCor3('coresSecondSection-cores cor3 showUp') 
            }
            if (window.scrollY >= 260) { 
                setCor4('coresSecondSection-cores cor4 showUp') 
            }
            if (window.scrollY >= 280) { 
                setCor5('coresSecondSection-cores cor5 showUp') 
            }
        })


    }, [])

    return (
        <div className='coresSecondSection'>
            <div className='coresSecondSection-area'>
                <div className={cor1}>
                    <ul>
                        <li><h3>Pantone</h3></li>
                        <li>1655 C</li>
                    </ul>
                    <ul>
                        <li><h3>RGB</h3></li>
                        <li>252 76 2</li>
                    </ul>
                    <ul>
                        <li><h3>HEX/HTML</h3></li>
                        <li>FC4C02</li>
                    </ul>
                    <ul>
                        <li><h3>CMYK</h3></li>
                        <li>0 77 100 0</li>
                    </ul>
                </div>
                <div className={cor2}>
                    <ul>
                        <li><h3>Pantone</h3></li>
                        <li>663 C</li>
                    </ul>
                    <ul>
                        <li><h3>RGB</h3></li>
                        <li>229 225 230</li>
                    </ul>
                    <ul>
                        <li><h3>HEX/HTML</h3></li>
                        <li>E5E1E6</li>
                    </ul>
                    <ul>
                        <li><h3>CMYK</h3></li>
                        <li>5 6 0 0</li>
                    </ul>
                </div>
                <div className={cor3}>
                    <ul>
                        <li><h3>Pantone</h3></li>
                        <li>4277 C</li>
                    </ul>
                    <ul>
                        <li><h3>RGB</h3></li>
                        <li>188 140 61</li>
                    </ul>
                    <ul>
                        <li><h3>HEX/HTML</h3></li>
                        <li>8F8B90</li>
                    </ul>
                    <ul>
                        <li><h3>CMYK</h3></li>
                        <li>41 36 26 9</li>
                    </ul>
                </div>
                <div className={cor4}>
                    <ul>
                        <li><h3>Pantone</h3></li>
                        <li>4280 C</li>
                    </ul>
                    <ul>
                        <li><h3>RGB</h3></li>
                        <li>37 42 54</li>
                    </ul>
                    <ul>
                        <li><h3>HEX/HTML</h3></li>
                        <li>252A36</li>
                    </ul>
                    <ul>
                        <li><h3>CMYK</h3></li>
                        <li>86 68 36 78</li>
                    </ul>
                </div>
                <div className={cor5}>
                    <ul>
                        <li><h3>Pantone</h3></li>
                        <li>Black 6 C</li>
                    </ul>
                    <ul>
                        <li><h3>RGB</h3></li>
                        <li>16 24 32</li>
                    </ul>
                    <ul>
                        <li><h3>HEX/HTML</h3></li>
                        <li>101820</li>
                    </ul>
                    <ul>
                        <li><h3>CMYK</h3></li>
                        <li>100 61 32 96</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}