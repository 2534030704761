import './style.css'

export  default function LogoFourthSection() {
    return (
        <div className='logoFourthSection'>
            <div className='logoFourthSection-area'>
                <img src='./area-de-seguranca.jpg' alt=''/>
            </div>
        </div>
    )
}