import './style.css'

export default function TipografiaSecondSection() {
    return (
        <div className='tipografiaSecondSection'>
            <div className='tipografiaSecondSection-area'>
                <div className='tipografiaSecondSection-textBox'>
                    <p>Variação de peso</p>
                    <h2><span id='thinFont'>Thin </span><span id='lightFont'>Light </span><span id='bookFont'>Book </span><span id='mediumFont'>Medium</span></h2>
                    <h2><span id='boldFont'>Bold </span><span id='extraBoldFont'>Extrabold </span><span id='blackFont'>Black</span></h2>
                </div>
                <div className='tipografiaSecondSection-textBox'>
                    <p>Variação de peso - italic</p>
                    <h2 id='thinItalicFont'>abcdefghijklmnopqrstuvwxyz</h2>
                    <h2 id='thinItalicFont' style={{marginBottom: '15px'}}>0123456789!@#$%^&*()</h2>
                    <h2 id='boldItalicFont'>abcdefghijklmnopqrstuvwxyz</h2>
                    <h2 id='boldItalicFont' style={{marginBottom: '15px'}}>0123456789!@#$%^&*()</h2>
                    <h2 id='extraBoldItalicFont'>abcdefghijklmnopqrstuvwxyz</h2>
                    <h2 id='extraBoldItalicFont' style={{marginBottom: '15px'}}>0123456789!@#$%^&*()</h2>
                </div>
            </div>
            <div className='tipografiaSecondSection-area'>
                <div className='tipografiaSecondSection-textBox'>
                    <h1 id='titleBox'>gerir com responsabilidade <span>faz parte de nosso dna</span></h1>
                    <p>Somos uma empresa global com presença local, atuando de forma multissetorial. Possuímos uma carteira de participações e investimentos composta por empresas em diferentes setores da economia em diversos estágios de desenvolvimento. Nossa busca é incansável para encontrar oportunidades de melhorar e gerar eficiência nos segmentos que atuamos.</p>
                    <a href='https://alloymit.com/' target='_blank'><p id='textSiteLink'><strong>alloymit</strong>.com</p></a>
                </div>
            </div>
        </div>
    )
}