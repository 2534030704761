import { useState } from 'react'
import './style.css'

import AzulEscuro from './Textura_papel_-_Azul_Escuro.png';
import Azul from './Textura_papel_-_Azul.png'
import Branco from './Textura_papel_-_Branco.png'
import Cinza from './Textura_papel_-_Cinza.png'
import Laranja from './Textura_papel_-_Laranja.png'

export default function LogoSecondSection() {

    const [color1, setColor1] = useState('firstColor active')
    const [color2, setColor2] = useState('secondColor')
    const [color3, setColor3] = useState('thirdColor')
    const [color4, setColor4] = useState('forthColor')
    const [color5, setColor5] = useState('fiveColor')
    const [bgColor, setBgColor] = useState(Branco)

    const [img, setImg] = useState('ALLOYMIT_LOGO_VARIACAO-2.png')

    function chanceColor1() {
        setColor1('firstColor active')
        setColor2('secondColor')
        setColor3('thirdColor')
        setColor4('forthColor')
        setColor5('fiveColor')
        setImg('ALLOYMIT_LOGO_VARIACAO-2.png')
        setBgColor(Branco)
    }

    function chanceColor2() {
        setColor1('firstColor')
        setColor2('secondColor active')
        setColor3('thirdColor')
        setColor4('forthColor')
        setColor5('fiveColor')
        setImg('ALLOYMIT_LOGO_VARIACAO.png')
        setBgColor(Laranja)
    }

    function chanceColor3() {
        setColor1('firstColor')
        setColor2('secondColor')
        setColor3('thirdColor active')
        setColor4('forthColor')
        setColor5('fiveColor')
        setImg('ALLOYMIT_LOGO.png')
        setBgColor(Azul)
    }

    function chanceColor4() {
        setColor1('firstColor')
        setColor2('secondColor')
        setColor3('thirdColor')
        setColor4('forthColor active')
        setColor5('fiveColor')
        setImg('ALLOYMIT_LOGO_VARIACAO-3.png')
        setBgColor(Cinza)
    }

    function chanceColor5() {
        setColor1('firstColor')
        setColor2('secondColor')
        setColor3('thirdColor')
        setColor4('forthColor')
        setColor5('fiveColor active')
        setImg('ALLOYMIT_LOGO.png')
        setBgColor(AzulEscuro)
    }

    return (
        <div className='logoSecondSection' style={{backgroundImage: "url(" + bgColor + ")"}}>
            <div className='logoSecondSection-area'>
                <img src={img} alt='Logo da AlloyMit' />
                <div className='botoes'>
                    <ul className="color-methods">
                        <li className="color-method color1">
                            <div onClick={chanceColor1} className={color1}></div>
                        </li>

                        <li className="color-method color2">
                            <div onClick={chanceColor2} className={color2}></div>
                        </li>

                        <li className="color-method color3">
                        <div onClick={chanceColor3} className={color3}></div>
                        </li>

                        <li className="color-method color4">
                        <div onClick={chanceColor4} className={color4}></div>
                        </li>

                        <li className="color-method color5">
                        <div onClick={chanceColor5} className={color5}></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}