import { useState } from 'react'
import './style.css'

export default function NossaEssenciaMainSection() {

    const [segurança, setSegurança] = useState('Segurança')
    const [responsabilidade, setResponsabilidade] = useState('Responsabilidade')
    const [tranquilidade, setTranquilidade] = useState('Tranquilidade')
    const [inovação, setInovação] = useState('Inovação')
    const [confiança, setConfiança] = useState('Confiança')
    const [estabilidade, setEstabilidade] = useState('Estabilidade')
    const [otimismo, setOtimismo] = useState('Otimismo')
    const [credibilidade, setCredibilidade] = useState('Credibilidade')
    const [fidelidade, setFidelidade] = useState('Fidelidade')

    const [textoBox, setTextoBox] = useState('')
    const [tituloBox, setTituloBox] = useState('')

    function handleSegurança() {
        setSegurança('Segurança checked')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade')
        setTituloBox('Segurança')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleResponsabilidade() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade checked')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade')
        setTituloBox('Responsabilidade')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleTranquilidade() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade checked')
        setInovação('Inovação')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade')
        setTituloBox('Tranquilidade')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleInovação() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação checked')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade')
        setTituloBox('Inovação')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleConfiança() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação')
        setConfiança('Confiança checked')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade')
        setTituloBox('Confiança')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleEstabilidade() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade checked')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade')
        setTituloBox('Estabilidade')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleOtimismo() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo checked')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade')
        setTituloBox('Otimismo')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleCredibilidade() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade checked')
        setFidelidade('Fidelidade')
        setTituloBox('Credibilidade')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    function handleFidelidade() {
        setSegurança('Segurança')
        setResponsabilidade('Responsabilidade')
        setTranquilidade('Tranquilidade')
        setInovação('Inovação')
        setConfiança('Confiança')
        setEstabilidade('Estabilidade')
        setOtimismo('Otimismo')
        setCredibilidade('Credibilidade')
        setFidelidade('Fidelidade checked')
        setTituloBox('Fidelidade')
        setTextoBox(' is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book')
    }

    return (
        <div className='nossaEssenciaMainSection'>
            <div className='nossaEssenciaMainSection-area'>
                    <img src='ICONE_ALLOY.png' alt=''/>
                <div className='nossaEssenciaMainSection-words'>
                    <p onClick={handleSegurança} className={segurança}>Segurança</p>
                    <p onClick={handleResponsabilidade} className={responsabilidade}>Responsabilidade</p>
                    <p onClick={handleTranquilidade} className={tranquilidade}>Tranquilidade</p>
                    <p onClick={handleInovação} className={inovação}>Inovação</p>
                    <p onClick={handleConfiança} className={confiança}>Confiança</p>
                    <p onClick={handleEstabilidade} className={estabilidade}>Estabilidade</p>
                    <p onClick={handleOtimismo} className={otimismo}>Otimismo</p>
                    <p onClick={handleCredibilidade} className={credibilidade}>Credibilidade</p>
                    <p onClick={handleFidelidade} className={fidelidade}>Fidelidade</p>
                </div>
                <div className='nossaEssenciaMainSection-box'>
                    <h1>{tituloBox}</h1>
                    <p>{textoBox}</p>
                </div>
            </div>
        </div>
    )
}