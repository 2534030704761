import '../App.css'
import HeaderAlt from '../components/Logo/HeaderAlt'
import NossaEssenciaMainSection from '../components/NossaEssencia/'



export default function NossaEssencia() {
    return (
        <div className='nossaEssencia'>
            <HeaderAlt />
            <NossaEssenciaMainSection />
        </div>
    )
}