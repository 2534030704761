import './App.css';
import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Logo from './pages/Logo';
import Cores from './pages/Cores';
import NossaEssencia from './pages/NossaEssencia';
import Tipografia from './pages/Tipografia';
import Papelaria from './pages/Papelaria';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/logo',
    element: <Logo />,
  },
  {
    path: '/cores',
    element: <Cores />,
  },
  {
    path: '/nossa-essencia',
    element: <NossaEssencia />,
  },
  {
    path: '/tipografia',
    element: <Tipografia />,
  },
  {
    path: '/papelaria',
    element: <Papelaria />,
  }
])

export { router }
