import '../App.css'
import FirstSection from '../components/Home/FirstSection'
import Header from '../components/Home/Header'
import SecondSection from '../components/Home/SecondSection'
import ThirdSection from '../components/Home/ThirdSection'

export default function Home() {
    return (
        <div className='home'>
            <div className='first-bg'>
                <Header />
                <FirstSection />
            </div>
            <div className='second-bg'>
                <SecondSection />
            </div>
            <div className='third-bg'>
                <ThirdSection />
            </div>
        </div>
    )
}