import './style.css'

export default function TipografiaMainSection() {
    return (
        <div className='tipografiaMainSection'>
            <div className='tipografiaMainSection-area'>
                <div className='tipografiaMainSection-titulo'>
                <h1>axiforma</h1>
                </div>
                <i className="fa-solid fa-caret-down"></i>
            </div>
        </div>
    )
}