import '../App.css'
import LogoIntersection2 from '../components/Logo/fithSection'
import LogoFourthSection from '../components/Logo/FourthSection'
import HeaderAlt from '../components/Logo/HeaderAlt'
import LogoMainSection from '../components/Logo/MainSection'
import LogoSecondSection from '../components/Logo/SecondSection'
import LogoSixSection from '../components/Logo/SixSection'
import LogoIntersection from '../components/Logo/ThirdSection'


export default function Logo() {
    return (
        <div className='logo'>
            <HeaderAlt />
            <LogoMainSection />
            <LogoSecondSection />
            <LogoIntersection />
            <LogoFourthSection />
            <LogoIntersection2 />
            <LogoSixSection />
        </div>
    )
}